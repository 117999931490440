import '../App.css';
import SubredditLink from './SubredditLink'

function SubredditList(props) {

    const subredditList = props.subredditList;

    return (
        <div className='subredditList'>
            <h5>Subreddits</h5>
            {subredditList.map((subreddit) => <SubredditLink name={subreddit.name} image={subreddit.image} loadSubreddit={props.loadSubreddit} />)}
        </div>
    )
}

export default SubredditList;