import '../App.css';

function Post(props){

    const post = props.postInfo;
    const author = post.data.author;
    let image = "" //post.data.preview.images ? post.data.preview.images[0].source.url : "";
    const title = post.data.title;
    const subreddit = post.data.subreddit;
    const likes = post.data.score;
    const link = post.data.url;

    const preview = post.data.preview;

    var description = post.data.selftext;

    let hasImage = false;

    if(typeof preview === "object"){
        if(typeof preview.images === "object"){
            if(typeof preview.images[0] !== "undefined"){
                hasImage = true;
                image = preview.images[0].source.url;
            }
        }
    } else if (post.data.thumbnail && post.data.thumbnail !== "self") {
        hasImage = true;
        image = post.data.thumbnail;
    }

    function handleClick(){
        window.open(link, '_blank');
    }
    
    return (
        <div className="post" onClick={handleClick}>
            <h5>{title}</h5>
            <div className='postFlex'>
                <div className='voteBar'>
                    <p>{likes}</p>
                    <p>Votes</p>
                </div>
                {hasImage && <img src={image} style={{width: 400}}/>}
                {post.data.selftext !== "" ? <p>{description}</p> : <p>Link/Media only</p>}
            </div>
            <div className='postBar'>
                <p className='posterInfo'>By u/{author} on r/{subreddit}</p>
                <p>Comments</p>
            </div>
        </div>
    );
}

export default Post;