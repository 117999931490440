import '../App.css';

function SubredditLink(props){

    const name = props.name;
    const image = props.image;

    function handleClick(e){
        props.loadSubreddit(name);
    }

    return (
        <div className='subredditLink' onClick={handleClick}>
            <img src={image ? image : "https://i.imgur.com/yd01iL2.jpeg"} />
            <p>{name ? name : "Placeholder Name"}</p>
        </div>
    );
}

export default SubredditLink;