import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import Post from "./Components/Post";
import './App.css';
import SubredditList from './Components/SubredditList';

function App() {

  const [postsInfo, setPostsInfo] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const searchBar = <input type='text' id='searchInput' value={searchQuery} onChange={e => setSearchQuery(e.target.value)}></input>;

  const [initialSearch, setInitialSearch] = useState(false);

  const fakeSearch = {
    "kind": "Listing",
    "data": {
        "modhash": "",
        "dist": 25,
        "facets": {},
        "after": "t3_ey6j1b",
        "geo_filter": "",
        "children": [
            {
                "kind": "t3",
                "data": {
                    "approved_at_utc": null,
                    "subreddit": "CrackWatch",
                    "selftext": "* [Steam](https://store.steampowered.com/app/1817230/HiFi_RUSH/)\n* [Screenshot](https://imgur.com/7dbaUXI)",
                    "author_fullname": "t2_3pu92i3a",
                    "saved": false,
                    "mod_reason_title": null,
                    "gilded": 0,
                    "clicked": false,
                    "title": "Hi-Fi RUSH has Denuvo",
                    "link_flair_richtext": [
                        {
                            "e": "text",
                            "t": "Article/News"
                        }
                    ],
                    "subreddit_name_prefixed": "r/CrackWatch",
                    "hidden": false,
                    "pwls": 7,
                    "link_flair_css_class": "article",
                    "downs": 0,
                    "thumbnail_height": null,
                    "top_awarded_type": null,
                    "hide_score": false,
                    "name": "t3_10l9ao1",
                    "quarantine": false,
                    "link_flair_text_color": null,
                    "upvote_ratio": 0.94,
                    "author_flair_background_color": null,
                    "subreddit_type": "public",
                    "ups": 455,
                    "total_awards_received": 0,
                    "media_embed": {},
                    "thumbnail_width": null,
                    "author_flair_template_id": null,
                    "is_original_content": false,
                    "user_reports": [],
                    "secure_media": null,
                    "is_reddit_media_domain": false,
                    "is_meta": false,
                    "category": null,
                    "secure_media_embed": {},
                    "link_flair_text": "Article/News",
                    "can_mod_post": false,
                    "score": 455,
                    "approved_by": null,
                    "is_created_from_ads_ui": false,
                    "author_premium": false,
                    "thumbnail": "self",
                    "edited": false,
                    "author_flair_css_class": null,
                    "author_flair_richtext": [],
                    "gildings": {},
                    "post_hint": "self",
                    "content_categories": null,
                    "is_self": true,
                    "mod_note": null,
                    "created": 1674679758,
                    "link_flair_type": "richtext",
                    "wls": 7,
                    "removed_by_category": null,
                    "banned_by": null,
                    "author_flair_type": "text",
                    "domain": "self.CrackWatch",
                    "allow_live_comments": true,
                    "selftext_html": "&lt;!-- SC_OFF --&gt;&lt;div class=\"md\"&gt;&lt;ul&gt;\n&lt;li&gt;&lt;a href=\"https://store.steampowered.com/app/1817230/HiFi_RUSH/\"&gt;Steam&lt;/a&gt;&lt;/li&gt;\n&lt;li&gt;&lt;a href=\"https://imgur.com/7dbaUXI\"&gt;Screenshot&lt;/a&gt;&lt;/li&gt;\n&lt;/ul&gt;\n&lt;/div&gt;&lt;!-- SC_ON --&gt;",
                    "likes": null,
                    "suggested_sort": null,
                    "banned_at_utc": null,
                    "view_count": null,
                    "archived": false,
                    "no_follow": false,
                    "is_crosspostable": false,
                    "pinned": false,
                    "over_18": false,
                    "preview": {
                        "images": [
                            {
                                "source": {
                                    "url": "https://external-preview.redd.it/J3HjjxzguCUhHOcJ337YRdnA2g2FkUebajPUpD6Z5OQ.jpg?auto=webp&amp;s=7dada636445d1b5d6f8015dffcc5fbceaee6f191",
                                    "width": 600,
                                    "height": 315
                                },
                                "resolutions": [
                                    {
                                        "url": "https://external-preview.redd.it/J3HjjxzguCUhHOcJ337YRdnA2g2FkUebajPUpD6Z5OQ.jpg?width=108&amp;crop=smart&amp;auto=webp&amp;s=18bf61b6c0f2f39b0dab7a8b91ff6332d1e1f508",
                                        "width": 108,
                                        "height": 56
                                    },
                                    {
                                        "url": "https://external-preview.redd.it/J3HjjxzguCUhHOcJ337YRdnA2g2FkUebajPUpD6Z5OQ.jpg?width=216&amp;crop=smart&amp;auto=webp&amp;s=8ebb3132855b9d03ad001caa24a1564f48ca96a0",
                                        "width": 216,
                                        "height": 113
                                    },
                                    {
                                        "url": "https://external-preview.redd.it/J3HjjxzguCUhHOcJ337YRdnA2g2FkUebajPUpD6Z5OQ.jpg?width=320&amp;crop=smart&amp;auto=webp&amp;s=19841e57a67d90686e7aa0a1d38d7beeb66739b9",
                                        "width": 320,
                                        "height": 168
                                    }
                                ],
                                "variants": {},
                                "id": "bKjAyKHwAeu6u3hu1YKsYMdiOiU6iZ8mqHd9koJxoic"
                            }
                        ],
                        "enabled": false
                    },
                    "all_awardings": [],
                    "awarders": [],
                    "media_only": false,
                    "link_flair_template_id": "f78cb48e-ea97-11e6-b124-0ee65b417558",
                    "can_gild": false,
                    "spoiler": false,
                    "locked": false,
                    "author_flair_text": null,
                    "treatment_tags": [],
                    "visited": false,
                    "removed_by": null,
                    "num_reports": null,
                    "distinguished": null,
                    "subreddit_id": "t5_3gnpj",
                    "author_is_blocked": false,
                    "mod_reason_by": null,
                    "removal_reason": null,
                    "link_flair_background_color": "#ff66ac",
                    "id": "10l9ao1",
                    "is_robot_indexable": true,
                    "report_reasons": null,
                    "author": "LWarnut",
                    "discussion_type": null,
                    "num_comments": 406,
                    "send_replies": true,
                    "whitelist_status": "some_ads",
                    "contest_mode": false,
                    "mod_reports": [],
                    "author_patreon_flair": false,
                    "author_flair_text_color": null,
                    "permalink": "/r/CrackWatch/comments/10l9ao1/hifi_rush_has_denuvo/",
                    "parent_whitelist_status": "some_ads",
                    "stickied": false,
                    "url": "https://www.reddit.com/r/CrackWatch/comments/10l9ao1/hifi_rush_has_denuvo/",
                    "subreddit_subscribers": 571116,
                    "created_utc": 1674679758,
                    "num_crossposts": 0,
                    "media": null,
                    "is_video": false
                }
            },
        ],
        "before": null
    }
  }

  function loadSubreddit(name){
    searchReddit("subreddit", name);
  }

  async function searchReddit(searchType, subreddit) {
    console.log(searchQuery);
    const encodedSearch = encodeURI(searchQuery);
    console.log(encodedSearch);
    if (searchType === "initial"){
      const response = await fetch(`https://www.reddit.com/hot.json?&raw_json=1&limit=100`);
      const searchResponse = await response.json();
      console.log(searchResponse);
      const endList = [];
      searchResponse.data.children.map((post) => {
        endList.push(post);
      })
      setPostsInfo(endList);
    } else if(searchType === "subreddit"){
      console.log("searching subreddit");
      const response = await fetch(`https://www.reddit.com/${subreddit}/hot.json?&raw_json=1&limit=100`);
      const searchResponse = await response.json();
      console.log(searchResponse);
      const endList = [];
      searchResponse.data.children.map((post) => {
        endList.push(post);
      })
      setPostsInfo(endList);
    } else {
      const response = await fetch(`https://www.reddit.com/search.json?q=${encodedSearch}&raw_json=1`);
      const searchResponse = await response.json();
      console.log(searchResponse);
      const endList = [];
      searchResponse.data.children.map((post) => {
        endList.push(post);
      })
      setPostsInfo(endList);
    }
  }

  if (!initialSearch){
    searchReddit("initial");
    setInitialSearch(true);
  }

  return (
    <div className='appHolder'>
        <div className="header">
          <h4>Gator Reddit</h4>
          {searchBar}
          <button onClick={searchReddit}>Search</button>
        </div>
        <div className='app'>
          <div className="mainapp">
            {postsInfo.map((post) => {
              return <Post postInfo={post}/>
            })}
          </div>
        <SubredditList loadSubreddit={loadSubreddit} subredditList={[{name: "r/Crocodiles", image: "https://styles.redditmedia.com/t5_2uara/styles/communityIcon_8pihyo4kyyd81.png"}, {name: "r/HiFiRush", image: "https://styles.redditmedia.com/t5_3mapv0/styles/communityIcon_z4rvods6ygyc1.png"}, {name: "r/sonicthehedgehog", image: "https://styles.redditmedia.com/t5_2rh21/styles/communityIcon_a6m1sdse7jsc1.png"}]} />
    </div>
    </div>

  );
}

export default App;
